@import '~antd/dist/antd.less';
@import './conf/theme/global-overrides.less';

@import './styles/fonts.less';
@import './styles/typo.less';
@import './styles/colors.less';
@import './styles/mixins.less';
@import './styles/icons.less';
@import './styles/helper.less';
@import './styles/custom-elements.less';
@import './styles/settings-page.less';

.app {
    .f-text-normal;

    * {
        font-feature-settings: "tnum" off !important; // Turns off tabular numerals for better readability.
    }

    .page-heading {
        .f-text-title;
        margin-bottom: 24px;
        .f-color-dark-black-s80;
    }

    .nav-side-bar {
        .ant-dropdown-trigger {
            transform: translateX(-50%);
            .position(absolute, @left: 50%, @bottom: 32px);
        }
    }

    // Stats Tile
    .stats-tile-card-wrapper {
        flex-grow: 1;

        &:focus,
        &:focus-visible {
            outline: 2px solid #49B9E4;
        }

        .stats-tile-card {
            padding: 8px;
            position: relative;
        }

        .tile-content {
            padding-left: 8px;
            border-left: 3px solid #f4bb40;
        }

        .tile-logo {
            .position(absolute, @top: 8px, @right: 8px);
        }

        .tile-count {
            .f-text-big-title;
            .f-color-dark-black-s80;
        }

        .tile-title {
            margin-top: 8px;
            margin-bottom: 0;
            .f-text-medium-content;
            .f-color-dark-black-s50;
        }
    }

    .platform-wrapper {
        gap: 8px;
        padding: 24px;
        background-color: #f4f6f8;
        justify-content: flex-start;

        .platform-tile-card-wrapper {
            width: calc(100% / 5 - 8px);

            @media (max-width: @screen-xl) {
                width: calc(100% / 3 - 8px);
            }
        }

        .stats-tile-card {
            padding: 16px;
        }

        .tile-logo {
            margin-left: 16px;
        }

        .tile-content {
            margin-left: 24px;
            .flex(flex, @direction: column-reverse);
        }

        .tile-title {
            margin: 0;
            .f-text-big-content;
            .f-color-dark-black-s50;
        }

        .tile-count {
            .f-text-title;
            .f-color-dark-black-s80;
        }
    }
}

.nav-user-menu {
    .ant-dropdown-menu {
        border-radius: 0;
        background-color: #ffffff;
        border: 1px solid #e9e9ea;
        box-shadow: 1px 1px 0px #d2d3d4;

        &-item {
            > a {
                color: #8f9094;
            }

            &:hover {
                background-color: transparent;

                > a {
                    color: #4c4e54;
                }
            }
        }
    }
}

@gray-1: #ffffff;@gray-2: #fafafa;@gray-3: #f5f5f5;@gray-4: #f0f0f0;@gray-5: #d9d9d9;@gray-6: #bfbfbf;@gray-7: #8c8c8c;@gray-8: #595959;@gray-9: #434343;@gray-10: #262626;@gray-11: #1f1f1f;@gray-12: #141414;@gray-13: #000000;@blue-s5: #f3fafd;@blue-s40: #A4DCF1;@blue-s100: #1CA8DD;@red-s60: #6D342C;@green-s60: #385923;@green-t60: #ADD395;@green-t160: #EFF6EA;@risk-very-low: #70B246;@dark-black-s5: #F8F8F9;@dark-black-s30: #BCBDBF;@dark-black-s60: #797A7F;@dark-black-s50: #8F9094;@dark-black-s80: #4C4E54;@dark-black-s100: #1F2229;@font-family: 'LLCirc', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@title-family: 'ITCAvantG', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@code-family: 'Roboto Mono', Consolas, 'Liberation Mono', Menlo, Courier, monospace;@border-radius-base: 5px;@font-size-base: 12px;@font-size-sm: 10px;@text-color-secondary: fade(black, 45%);@btn-font-weight: 600;@btn-border-style: @border-style-base;@btn-shadow: 0px 0px 4px 0px #0000001a;@btn-default-border: @gray-5;@input-border-color: @gray-5;@select-border-color: @gray-5;@typography-title-font-weight: 700;@tag-default-bg: transparent;@tag-default-color: @gray-9;@layout-header-height: 60px;@layout-header-padding: 0 20px;@screen-min-support: 1320px;@screen-max-support: 1880px;@screen-sm: 576px;@screen-md: 768px;@screen-lg: 992px;@screen-xl: 1200px;@primary-color: #1890ff;@primary-color-light: #F0F5FF;@primary-color-dark: #002766;@layout-header-background: white;@layout-header-color: #434343;@layout-body-background: white;@component-background: white;@body-background: white;@border-color-base: #ececec;@text-color: fade(black, 85%);@heading-color: fade(black, 85%);@background-color-light: hsv(0, 0, 98%);@divider-color: fade(black, 6%);@disabled-color: fade(black, 25%);@steps-nav-arrow-color: fade(black, 25%);@homepage-background-upper-fade: #FFFFFF;@homepage-background-lower-fade: #FFFFFF;@homepage-text-color: #434343;@box-shadow: 0px 0px 30px 0px rgb(239 239 239);@box-shadow-hover: 0px 1px 0px 0.5px rgb(239 239 239);@highlight-color: #E6F4FF;@highlight-border-color: #BAE0FF;